import React from "react";
import {
  $CompetitorTablePanel,
  $Header,
  $HeaderSuffix,
  $SubHeader,
  $MainTable,
} from "@styles/components/elements/CompetitorTablePanel";

export interface ICompetitorTable {
  title: string;
  titleSuffix?: string;
  description: string;
  headers: Array<string>;
  list: Array<any>;
}

const CompetitorTable: React.FC<ICompetitorTable> = ({
  title,
  titleSuffix,
  description,
  headers,
  list,
}) => {
  return (
    <$CompetitorTablePanel>
      <$Header>{title}</$Header>
      {titleSuffix && (
        <$HeaderSuffix>
          <div dangerouslySetInnerHTML={{ __html: titleSuffix }} />
        </$HeaderSuffix>
      )}
      <div style={{ clear: "both" }} />
      <$SubHeader>{description}</$SubHeader>
      <$MainTable>
        <thead>
          <tr>
            {headers.map((header, index) => {
              return <th key={`pr-competitor-table-${index}`}>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {list.map((row, index) => {
            return (
              <tr
                key={`pr-competitor-table-row-${index}`}
                className={row.highlight ? "highlighted" : undefined}
              >
                <td>
                  <a href={row.url} target="_blank">
                    {row.title}
                  </a>
                  <span>{row.address}</span>
                </td>
                <td className={row.showStar ? "show-star" : undefined}>
                  {row.value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </$MainTable>
    </$CompetitorTablePanel>
  );
};

export default CompetitorTable;
