import styled from "styled-components";
import { colors } from "../../colors";
import bookADemoImage from "../../../images/Anthony-B.png";

export const $ProspectReportHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const $ProspectReportWhiteLabelLogo = styled.img`
  max-height: 135px;
  max-width: 250px;
`;

export const $ProspectReportHeaderTitle = styled.h1`
  color: ${colors.catalinaBlue};
  font-family: "Open Sans";
  font-size: 48px;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 9px;
  margin: 0;
`;

export const $ProspectReportCreationDate = styled.p`
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
  color: ${colors.baseCopy};
`;

export const BusinessDetailsContainer = styled.div`
  background-color: ${colors.grayBackground};
  border-radius: 10px;
  border: 2px solid ${colors.grayBorder};
  width: 100%;
  min-width: 850px;
  min-height: 230px;
  margin-top: 80px;
  display: table;
`;

export const BusinessDetailsRow = styled.div`
  display: table-row;
`;

export const BusinessName = styled.td`
  font-family: "Open Sans";
  color: ${colors.catalinaBlue} !important;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 36px;
`;

export const BusinessDetailsData = styled.table`
  td {
    padding-left: 30px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: ${colors.paleSky};
  }

  th {
    text-align: left;
    font-family: "Open Sans";
    color: ${colors.catalinaBlue};
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
  }

  a,
  a:hover,
  a:focus {
    color: ${colors.primaryBase};
  }

  padding: 24px 40px;
  display: table-cell;
  width: 100%;
`;

export const BusinessDetailsMapContainer = styled.div`
  display: table-cell;
  vertical-align: top;
`;

export const BusinessDetailsMap = styled.img`
  height: 228px;
  width: 228px;
  display: block;
  border-radius: 10px;
`;

export const $MetricsOverviewContainer = styled.div`
  width: 100%;
  min-width: 850px;
  page-break-inside: avoid;
  margin-top: 160px;
`;

export const $MetricsOverviewDescription = styled.div`
  margin: 24px 0px 40px 0px;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.baseCopy};
  margin-top: 48px;

  .highlight {
    color: ${colors.primaryBase};
  }
`;

export const ProgressBarContainer = styled.div`
  margin-top: 92%;
`;

export const Star = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 15px;
  margin-top: 2px;

  &::after {
    color: ${colors.treePoppy};
  }
`;

export type ValueTitleContainerProps = {
  showStar?: boolean;
};

export const ValueTitleContainer = styled.div<ValueTitleContainerProps>`
  position: relative;
  margin-top: 48px;
  margin-right: ${(props) => (props.showStar ? "20px" : "0px")};
`;

export const CTAWrapper = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  height: 311px;
  border: 2px solid ${colors.grayBorder};
  background-color: ${colors.grayBackground};
`;

export const CTAStartFreeTrialWrapper = styled(CTAWrapper)`
  border: 2px solid ${colors.grayBorder};
  background-color: ${colors.grayBackground};
  width: 40%;
  margin-right: 2%;
`;

export const CTAScheduleADemoWrapper = styled(CTAWrapper)`
  border: 2px solid ${colors.blueSky};
  background-color: ${colors.blueSky};
  width: 58%;
`;

export const CTAWhitelabelButtonWrapper = styled.div`
  margin-top: 46px;
  text-align: center;
`;

export const FreeTrialCopyHeader = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 24px;
  color: ${colors.catalinaBlue};
`;

export const CTACopyContent = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;

  p {
    margin: 0px;
  }
`;

export const FreeTrialCopyContent = styled(CTACopyContent)`
  margin-top: 48px;
  color: ${colors.lightGray3};
`;

export const BookADemoImage = styled.div`
  height: 96px;
  width: 96px;
  background-image: ${(props) => `url(${bookADemoImage})`};
  background-size: 96px;
  background-repeat: no-repeat;
  margin-top: -24px;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid #fff;
  border-radius: 96px;
`;

export const ScheduleADemoCopyContent = styled(CTACopyContent)`
  margin-top: 18px;
  color: ${colors.white};
`;

export const CTAButton = styled.a`
  display: inline-block;
  border: solid;
  border-radius: 24px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none;
  border-width: 2px;
  padding: 8px 20px;
  color: ${colors.white};

  &:hover {
    text-decoration: none;
    color: ${colors.white};
  }

  &:focus {
    text-decoration: none;
    color: ${colors.white};
  }
`;

export const FreeTrialButton = styled(CTAButton)`
  margin-top: 49px;
  background-color: ${colors.blueSky};
  border-color: ${colors.blueSky};
`;

export const ScheduleADemoButton = styled(CTAButton)`
  margin-top: 30px;
  background-color: ${colors.blueSky};
  border-color: ${colors.white};
`;

export const WhitelabelActionButton = styled(CTAButton)`
  background-color: ${colors.blueSky};
  border-color: ${colors.blueSky};
`;

export const ContactContainer = styled.div`
  padding: 48px;
  background: ${colors.grayBackground};
  border: 2px solid ${colors.grayBorder};
  border-radius: 10px;
`;

export const ContactTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const ContactTitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: ${colors.catalinaBlue};
  margin: 0 8px 0 0;
`;

export const ContactTitleImg = styled.img`
  height: 53px;
`;

export const ContactInfo = styled.a`
  color: ${colors.baseCopy};
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 8px;
  text-align: center;
  display: block;
`;
