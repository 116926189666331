import React, { FunctionComponent } from "react";
import {
  CTAStartFreeTrialWrapper,
  FreeTrialCopyHeader,
  FreeTrialCopyContent,
  FreeTrialButton,
} from "@styles/components/myreports/Prospect";
import gradeusLogo from "../../../assets/gradeus-logo-transparent.png";

const CTAStartFreeTrial: FunctionComponent = (props: any) => {
  return (
    <CTAStartFreeTrialWrapper data-testid="pr-gradeus-cta-free-trial">
      <FreeTrialCopyHeader>
        <img src={gradeusLogo} style={{ maxHeight: "40px" }} />
      </FreeTrialCopyHeader>
      <FreeTrialCopyContent>
        <p>2 week free trial</p>
        <p>No Commitments</p>
      </FreeTrialCopyContent>
      <FreeTrialButton
        id="prospect-report-cta-start-free-trial"
        href="/signup"
        target="_blank"
      >
        Start a Free Trial Today
      </FreeTrialButton>
    </CTAStartFreeTrialWrapper>
  );
};

export default CTAStartFreeTrial;
