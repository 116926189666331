import React, { FunctionComponent } from "react";
import {
  CTAWhitelabelButtonWrapper,
  WhitelabelActionButton,
} from "@styles/components/myreports/Prospect";

export type CTAWhitelabelButtonProps = {
  href: string;
};

const CTAWhitelabelButton: FunctionComponent<CTAWhitelabelButtonProps> = (
  props
) => {
  return (
    <CTAWhitelabelButtonWrapper data-testid="pr-whitelabel-cta">
      <WhitelabelActionButton
        id="prospect-report-cta-contact-whitelabeler"
        href={props.href}
        target="_blank"
      >
        Contact Us Today
      </WhitelabelActionButton>
    </CTAWhitelabelButtonWrapper>
  );
};

export default CTAWhitelabelButton;
