import styled from "styled-components";
import { colors } from "@styles/colors";

export const $ProgressBarContainer = styled.div`
  bottom: 0px;
  width: 100%;
`;

export const $ProgressBarPanelContainer = styled.a`
  display: block;
  position: relative;
  height: 200px;
  width: 100%;
  min-width: 200px;
  max-width: 33%;

  border-radius: 10px;
  border: 2px solid #e6ecf1;
  background-color: ${colors.grayBackground};

  > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export type ProgressBarPanelContentStyle = {
  fontSize: string;
  fontWeight: string;
  lineHeight: string;
  marginTop: string;
  color: string;
  width?: string;
  maxWidth?: string;
};

export const $ProgressBarPanelContent = styled.div<ProgressBarPanelContentStyle>`
  font-family: Open Sans;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "36px")};
  color: ${(props) => (props.color ? props.color : colors.catalinaBlue)};
  text-align: center;
`;

export const $ProgressBarPanelContentUp = styled($ProgressBarPanelContent)`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "24px")};
  overflow: hidden;
  width: ${(props) => (props.width ? props.width : "1000px")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "90%")};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const $ProgressBarPanelContentDown = styled($ProgressBarPanelContent)`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "48px")};
`;

export const $ProgressBarPanelContentDownContainer = styled.div<{
  showStar?: boolean;
}>`
  margin-top: 48px;
  transform: translateX(
    ${(props) => (props.showStar ? "-25px" : "-50%")}
  ) !important;
`;

export const $ProgressBarPanelStar = styled.span<{
  styling?: any;
  size?: string;
}>`
  &::after {
    content: "\\f005";
  }

  position: absolute;
  font-weight: 900;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  text-decoration: none;
  color: ${colors.treePoppy};
  margin-left: 40px;
  bottom: 5px;
  width: 100%;
`;
