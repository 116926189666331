import React, { useState, useRef } from "react";
import PlacesWithStandaloneSearchBox from "@components/builder/sites/Wizard/FindMyBusinessSearchBar";
import { BaseInput, BaseLabel } from "@styles/components/elements/Inputs";
import ModalWithCTA from "@components/base/modals/ModalWithCTA";
import PopupModal from "@components/base/modals/PopupModal";
import { ErrorFields } from "@styles/components/elements/Paragraphs";
import { PillButton } from "@styles/components/elements/Buttons";
import { $RecaptchaStyle } from "@styles/components/elements/Captcha";
import { GrayBodyCopy } from "@styles/components/elements/Paragraphs";
import { SimpleLink } from "@styles/components/elements/Links";
import ServerErrorModal from "@components/base/modals/ServerErrorModal";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { getCSRFToken } from "@utils/utils";
import mailIcon from "../../../assets/icons/email-blue.svg";

export interface IWhitelabelWidget {
  site_uuid: string;
  callToAction?: string;
  useCaptcha?: boolean;
  captchaSiteKey?: string;
  sitePhoneNumber?: string;
  withModalCTA?: boolean;
}

interface WhitelabelWidgetData {
  googleMapsUrl: string;
  googlePlaceObject: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

const errorFields = (value) => {
  return <ErrorFields>{value && value.message}</ErrorFields>;
};

const WhitelabelWidget: React.FC<IWhitelabelWidget> = ({
  site_uuid,
  callToAction = undefined,
  useCaptcha = false,
  captchaSiteKey = undefined,
  sitePhoneNumber = undefined,
  withModalCTA = false,
}) => {
  const [modalErrorMessage, setModalErrorMessage] = useState(null);
  const recaptchaRef = useRef();
  const { handleSubmit, register, errors } = useForm<WhitelabelWidgetData>({
    mode: "onChange",
  });
  const [googleLocation, setGoogleLocation] = useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const onSubmit = async ({
    googleMapsUrl,
    googlePlaceObject,
    contactName,
    contactPhone,
    contactEmail,
  }) => {
    let formData = {
      site_uuid: site_uuid,
      google_maps_url: googleMapsUrl,
      google_place_object: googlePlaceObject,
      name: contactName,
      phone: contactPhone,
      email: contactEmail,
    };

    if (useCaptcha) {
      // @ts-ignore
      const captchaToken = await recaptchaRef.current.getValue();
      formData["g-recaptcha-response"] = captchaToken;
      // @ts-ignore
      recaptchaRef.current.reset();
    }

    axios
      .post("/agency/prospect_reports", formData, {
        headers: {
          Accept: "application/json",
          "X-CSRF-TOKEN": getCSRFToken(),
        },
      })
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch((error) => {
        setModalErrorMessage(error.response.data.error);
      });
  };

  const renderReCaptcha = () => {
    return (
      <$RecaptchaStyle>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={captchaSiteKey}
          onExpired={refreshPageError}
          onErrored={refreshPageError}
        />
      </$RecaptchaStyle>
    );
  };

  const refreshPageError = () =>
    setModalErrorMessage("Please refresh page and try again!");
  const closeErrorsModal = () => setModalErrorMessage(null);
  const afterServerErrorModal = () => (document.body.style.overflow = "hidden");

  const modalBody = () => {
    if (withModalCTA) {
      return (
        <GrayBodyCopy>
          It will take a few minutes to prepare your report,
          so we'll email it to you as soon as it's ready.
          If you're ready to learn how to improve your reputation,
          schedule a call with one of our experts.
        </GrayBodyCopy>
      );
    } else {
      if (sitePhoneNumber === null || sitePhoneNumber === undefined) {
        return (
          <GrayBodyCopy>
            It will take a few minutes to prepare your report,
            so we'll email it to you as soon as it's ready.
            Contact us if you're ready to learn how to improve your reputation.
          </GrayBodyCopy>
        );
      } else {
        return (
          <GrayBodyCopy>
            It will take a few minutes to prepare your report,
            so we'll email it to you as soon as it's ready.
            If you're ready to learn how to improve your reputation, call one of our experts at &nbsp;
            <SimpleLink href={`tel:${sitePhoneNumber}`} target="_blank">
              {sitePhoneNumber}
            </SimpleLink>
            .
          </GrayBodyCopy>
        );
      }
    }
  };

  return (
    <div style={{ margin: "10px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <BaseLabel>Business Name*</BaseLabel>
          <PlacesWithStandaloneSearchBox
            googleData={setGoogleLocation}
            placeholderText="Type name here"
          />
          <input
            ref={register({
              required: "Please enter your business name.",
            })}
            type="hidden"
            name="googleMapsUrl"
            value={googleLocation == null ? "" : googleLocation[0].url}
          />
          <input
            ref={register}
            type="hidden"
            name="googlePlaceObject"
            value={
              googleLocation == null ? "" : JSON.stringify(googleLocation[0])
            }
          />
          {errorFields(errors.googleMapsUrl)}
        </div>
        <div>
          <BaseLabel>Name*</BaseLabel>
          <BaseInput
            name="contactName"
            placeholder="Type name here"
            errorMessage={!!errors.contactName}
            ref={register({
              required: "Please enter your full name.",
            })}
          />
          {errorFields(errors.contactName)}
        </div>
        <div>
          <BaseLabel>Email*</BaseLabel>
          <BaseInput
            name="contactEmail"
            placeholder="Email"
            errorMessage={!!errors.contactEmail}
            ref={register({
              required: "Required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message:
                  "There was an error with your email address. Please re-enter.",
              },
            })}
          />
          {errorFields(errors.contactEmail)}
        </div>
        <div>
          <BaseLabel>Phone*</BaseLabel>
          <BaseInput
            name="contactPhone"
            placeholder="Phone"
            errorMessage={!!errors.contactPhone}
            ref={register({
              required: "Required.",
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message:
                  "There was an error with your phone number. Please re-enter.",
              },
            })}
          />
          {errorFields(errors.contactPhone)}
        </div>
        {useCaptcha && (
          <div style={{ marginTop: "15px" }}>{renderReCaptcha()}</div>
        )}
        <div style={{ marginTop: "48px" }}>
          <PillButton>
            {callToAction === undefined ? "Get My Scorecard" : callToAction}
          </PillButton>
        </div>
        {openSuccessModal &&
          (withModalCTA ? (
            <ModalWithCTA
              width="402px"
              headerIcon={mailIcon}
              header="Your Report is on its way!"
              ctaLabel="Schedule a Call"
              ctaURL="https://www.grade.us/home/request-a-demo/"
              openInNewTab={true}
            >
              {modalBody()}
            </ModalWithCTA>
          ) : (
            <PopupModal
              width="402px"
              headerIcon={mailIcon}
              header="Your Report is on its way!"
            >
              {modalBody()}
            </PopupModal>
          ))}
      </form>
      <ServerErrorModal
        isOpen={!!modalErrorMessage}
        onAfterOpen={afterServerErrorModal}
        onRequestClose={closeErrorsModal}
        errorText={modalErrorMessage}
      />
    </div>
  );
};

export default WhitelabelWidget;
