import React, { FunctionComponent } from "react";
import StyledTitle, { IStyledTitleProps } from "../../elements/StyledTitle";
import ProgressBar, { IProgressBarProps } from "../../elements/ProgressBar";
import ProgressBarPanelGroup, {
  IProgressBarPanelGroupProps,
} from "../../elements/ProgressBarPanelGroup";
import CompetitorTable, { ICompetitorTable } from "./CompetitorTable";
import {
  $MetricsOverviewContainer,
  $MetricsOverviewDescription,
} from "@styles/components/myreports/Prospect";
import QuotesPanelGroup, {
  QuotesPanelGroupProps,
} from "../../elements/QuotesPanelGroup";

export type MetricsOverviewProps = {
  styledTitleProps: IStyledTitleProps;
  progressBarProps?: IProgressBarProps;
  description: string;
  progressBarPanelGroup?: IProgressBarPanelGroupProps;
  quotesPanelGroup?: QuotesPanelGroupProps;
  listPanel?: ICompetitorTable;
};

const MetricsOverview: FunctionComponent<MetricsOverviewProps> = (props) => {
  return (
    <$MetricsOverviewContainer>
      <StyledTitle {...props.styledTitleProps} />
      {props.progressBarProps && <ProgressBar {...props.progressBarProps} />}
      <$MetricsOverviewDescription
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      {props.progressBarPanelGroup && (
        <ProgressBarPanelGroup {...props.progressBarPanelGroup} />
      )}
      {props.quotesPanelGroup && (
        <QuotesPanelGroup {...props.quotesPanelGroup} />
      )}
      {props.listPanel && <CompetitorTable {...props.listPanel} />}
    </$MetricsOverviewContainer>
  );
};

export default MetricsOverview;
