import React, { FunctionComponent } from "react";
import {
  BusinessDetailsContainer,
  BusinessDetailsRow,
  BusinessDetailsData,
  BusinessDetailsMapContainer,
  BusinessDetailsMap,
  BusinessName,
} from "@styles/components/myreports/Prospect";

export type BusinessDetailsProps = {
  name: string;
  address: string;
  type: string;
  phoneNumber: string;
  website: string;
  latitude: string;
  longitude: string;
};

const BusinessDetails: FunctionComponent<BusinessDetailsProps> = (
  props: BusinessDetailsProps
) => {
  const googleMapStaticImageURL = () => {
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${props.latitude},${props.longitude}&zoom=15&size=228x228&maptype=roadmap&markers=color:red%7C${props.latitude},${props.longitude}&key=${process.env.GPLACES_API_KEY}`;

    return process.env.NODE_ENV == "test" ? null : mapUrl;
  };

  return (
    <BusinessDetailsContainer>
      <BusinessDetailsRow>
        <BusinessDetailsData>
          <tbody>
            <tr>
              <th>Name:</th>
              <BusinessName>{props.name}</BusinessName>
            </tr>
            <tr>
              <th>Address:</th>
              <td>{props.address}</td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>{props.type}</td>
            </tr>
            <tr>
              <th>Phone Number:</th>
              <td>{props.phoneNumber}</td>
            </tr>
            <tr>
              <th>Website:</th>
              <td>
                <a href={props.website}>{props.website}</a>
              </td>
            </tr>
          </tbody>
        </BusinessDetailsData>
        <BusinessDetailsMapContainer>
          <BusinessDetailsMap
            src={googleMapStaticImageURL()}
            alt={props.name}
          />
        </BusinessDetailsMapContainer>
      </BusinessDetailsRow>
    </BusinessDetailsContainer>
  );
};

export default BusinessDetails;
