import styled from "styled-components";
import { colors } from "../../colors";
import { Panel } from "../myreports/Dashboard";
import { Table } from "../elements/Table";

export const $CompetitorTablePanel = styled(Panel)`
  margin-top: 54px;
`;

export const $Header = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.catalinaBlue};
  float: left;
`;

export const $HeaderSuffix = styled.div`
  float: left;
  height: 36px;
  margin-left: 20px;

  * {
    line-height: 36px;
  }
`;

export const $SubHeader = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-top: 16px;
  color: ${colors.baseCopy};
`;

export const $MainTable = styled(Table)`
  margin-top: 54px;

  thead > tr {
    border-bottom: none;
  }

  thead > tr > th:nth-child(1) {
    width: 75%;
  }

  thead > tr > th:nth-child(2) {
    width: 25%;
  }

  th,
  td {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
  }

  th {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.catalinaBlue};
  }

  td {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: ${colors.baseCopy};
    padding-top: 22px;
    padding-bottom: 22px;
  }

  td > a {
    display: block;
    text-decoration: none;
    color: ${colors.baseCopy};

    &:hover {
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }
  }

  td > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.baseCopy};
  }

  tbody > tr:last-child {
    border-bottom: none;
  }

  tbody > tr.highlighted > td {
    font-weight: bold;
  }

  tbody > tr.highlighted > td:nth-child(1) > a {
    color: ${colors.primaryBase};
  }

  td.show-star::after {
    content: "\\f005";
    font-weight: 900;
    font-size: 20px;
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    text-decoration: none;
    color: ${colors.treePoppy};
    margin-left: 8px;
    vertical-align: bottom;
  }
`;
