import React, { FunctionComponent } from "react";
import MainHeader from "./MainHeader";
import CTA, { CTAProps } from "./CTA";
import BusinessDetails, { BusinessDetailsProps } from "./BusinessDetails";
import MetricsOverview, { MetricsOverviewProps } from "./MetricsOverview";

export type ProspectReportProps = {
  created: string;
  businessDetails: BusinessDetailsProps;
  volume: MetricsOverviewProps;
  rating: MetricsOverviewProps;
  recency: MetricsOverviewProps;
  sentiment: MetricsOverviewProps;
  overallScore: MetricsOverviewProps;
  ctaProps: CTAProps;
};

const ProspectReport: FunctionComponent<ProspectReportProps> = (
  props: ProspectReportProps
) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <MainHeader created={props.created} {...props.ctaProps} />
        <BusinessDetails {...props.businessDetails} />
        <MetricsOverview {...props.overallScore} />
        <MetricsOverview {...props.volume} />
        <MetricsOverview {...props.rating} />
        <MetricsOverview {...props.recency} />
        <MetricsOverview {...props.sentiment} />
        <CTA {...props.ctaProps} />
      </div>
    </div>
  );
};

export default ProspectReport;
