import styled from "styled-components";
import { colors } from "@styles/colors";

export const $StyledTitleContainer = styled.div`
  text-align: center;
  margin-bottom: 18px;
`;

export type StyledTitleProps = {
  color?: string;
  fontSize?: string;
};

export const $StyleTitleBase = styled.div<StyledTitleProps>`
  display: inline-block;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.5px;
  font-family: Open Sans;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "48px")};
  color: ${(props) => (props.color ? props.color : colors.catalinaBlue)};
`;

export const $StyledTitlePrefix = styled($StyleTitleBase)``;

export const $StyledTitleWord = styled($StyleTitleBase)`
  margin-left: 10px;
`;

export const $StyledTitleSuffix = styled($StyleTitleBase)``;

export const $StyledTitleSuffixImg = styled.img`
  max-height: 40px;
`;
