import React from "react";
import {
  $ProspectReportHeaderTitle,
  $ProspectReportCreationDate,
  $ProspectReportHeaderContainer,
  $ProspectReportWhiteLabelLogo,
} from "@styles/components/myreports/Prospect";
import { BodyCopy } from "@styles/components/elements/Paragraphs";
import { colors } from "@styles/colors";

export interface IMainHeader {
  created: string;
  businessName?: string;
  businessLink?: string;
  factorsForImprovements?: string;
  whitelabelCTALink?: string;
  whitelabelLogoUrl?: string;
  whitelabelTitle?: string;
}

const MainHeader: React.FC<IMainHeader> = ({
  created,
  whitelabelCTALink,
  whitelabelLogoUrl,
  whitelabelTitle,
}) => {
  return (
    <$ProspectReportHeaderContainer>
      <div>
        <$ProspectReportHeaderTitle>
          Reputation Scorecard
        </$ProspectReportHeaderTitle>
        {whitelabelTitle && (
          <BodyCopy
            fS="24px"
            color={colors.baseCopy}
            style={{ marginBottom: "12px" }}
          >
            Powered by {whitelabelTitle}
          </BodyCopy>
        )}
        <$ProspectReportCreationDate>
          Created: {created}
        </$ProspectReportCreationDate>
      </div>

      {whitelabelLogoUrl && (
        <a href={whitelabelCTALink} target="blank">
          <$ProspectReportWhiteLabelLogo src={whitelabelLogoUrl} />
        </a>
      )}
    </$ProspectReportHeaderContainer>
  );
};

export default MainHeader;
