import styled from "styled-components";
import { colors } from "@styles/colors";

export const $PanelsGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  > a {
    margin-top: 10px;
  }

  > a:not(:last-child) {
    margin-right: 10px;
  }
`;

export const $PanelsGroupTitle = styled.div`
  font-family: "Open Sans";
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  font-weight: 700;
  font-style: normal;
  color: ${colors.catalinaBlue};
  margin-bottom: 24px;
`;
