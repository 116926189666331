import React, { FunctionComponent } from "react";
import {
  CTAScheduleADemoWrapper,
  BookADemoImage,
  ScheduleADemoCopyContent,
  ScheduleADemoButton,
} from "@styles/components/myreports/Prospect";

const CTAScheduleADemo: FunctionComponent = (props: any) => {
  return (
    <CTAScheduleADemoWrapper data-testid="pr-gradeus-cta-schedule-a-demo">
      <BookADemoImage />
      <ScheduleADemoCopyContent>
        <p>Get 50% for first three months</p>
        <p>by scheduling time with an</p>
        <p>onboarding specialist.</p>
      </ScheduleADemoCopyContent>
      <ScheduleADemoButton
        id="prospect-report-cta-schedule-a-demo"
        href="https://calendly.com/grade-us-sales-team/grade-us-multi-location-demo"
        target="_blank"
      >
        Schedule a Demo Today
      </ScheduleADemoButton>
    </CTAScheduleADemoWrapper>
  );
};

export default CTAScheduleADemo;
