import React, { FunctionComponent } from "react";

import ProgressBar from "../ProgressBar";
import { colors } from "@styles/colors";
import {
  $ProgressBarContainer,
  $ProgressBarPanelContainer,
  $ProgressBarPanelContentDown,
  $ProgressBarPanelContentDownContainer,
  $ProgressBarPanelContentUp,
  $ProgressBarPanelStar,
  ProgressBarPanelContentStyle,
} from "./ProgressBarPanel.sc";

export interface IProgressBarPanelProps {
  contentUp: string;
  contentDown?: string;
  progress?: number;
  min?: number;
  max?: number;
  colorThresholds?: Array<number>;
  showStar?: boolean;
  // provide custom colors with this parameter
  progressBarColors?: Array<Array<string>>;
  styleContentUp?: ProgressBarPanelContentStyle;
  styleContentDown?: ProgressBarPanelContentStyle;
  isProgressDecimal?: boolean;
  url?: string;
}

const ProgressBarPanel: FunctionComponent<IProgressBarPanelProps> = (
  props: IProgressBarPanelProps
) => {
  const progress = () => {
    const delta = props.max - props.min;
    const percentageUnit = 100.0 / delta;
    // in case min is > 0 subtract min from value
    const value = props.progress < props.min ? props.min : props.progress;
    return (value - props.min) * percentageUnit;
  };

  const progressBarColorsPalette = () => {
    if (props.progressBarColors) {
      return props.progressBarColors;
    } else if (props.colorThresholds.length == 1) {
      return [
        [colors.carnationRed, colors.carnationRedFaded],
        [colors.appleGreen, colors.appleGreenFaded],
      ];
    } else {
      return [
        [colors.carnationRed, colors.carnationRedFaded],
        [colors.treePoppy, colors.treePoppyFaded],
        [colors.appleGreen, colors.appleGreenFaded],
      ];
    }
  };

  const progressColors = () => {
    if (props.colorThresholds.length == 1)
      return twoProgressColors(progressBarColorsPalette());

    return threeProgressColors(progressBarColorsPalette());
  };

  const twoProgressColors = (colors: Array<Array<string>>) => {
    const threshold = props.colorThresholds[0];
    if (props.progress < threshold) return colors[0];
    else return colors[1];
  };

  const threeProgressColors = (colors: Array<Array<string>>) => {
    if (props.progress < props.colorThresholds[0]) return colors[0];
    else if (
      props.progress >= props.colorThresholds[0] &&
      props.progress <= props.colorThresholds[1]
    )
      return colors[1];
    else return colors[2];
  };

  const contentDown = () => {
    if (props.contentDown) return props.contentDown;
    else if (props.isProgressDecimal) return props.progress.toFixed(1);
    else return props.progress;
  };

  const progressBarColors = props.colorThresholds ? progressColors() : null;

  const styleContentUp = () => {
    return {
      ...props.styleContentUp,
      ...{ fontSize: props.contentUp.length > 24 ? "18px" : null },
    };
  };

  return (
    <$ProgressBarPanelContainer
      href={props.url ? props.url : "#"}
      target={props.url ? "_blank" : "_self"}
      style={{ cursor: props.url ? "pointer" : "default" }}
    >
      <$ProgressBarPanelContentUp {...styleContentUp()}>
        {props.contentUp}
      </$ProgressBarPanelContentUp>
      <$ProgressBarPanelContentDownContainer showStar={props.showStar}>
        <$ProgressBarPanelContentDown {...props.styleContentDown}>
          {contentDown()}
        </$ProgressBarPanelContentDown>
        {props.showStar ? <$ProgressBarPanelStar /> : null}
      </$ProgressBarPanelContentDownContainer>
      {props.colorThresholds && (
        <$ProgressBarContainer>
          <ProgressBar
            value={progress()}
            color={progressBarColors[0]}
            backgroundColor={progressBarColors[1]}
            height={"16px"}
            borderRadius={"0px"}
            backgroundBorderRadius={"0px 0px 10px 10px"}
          />
        </$ProgressBarContainer>
      )}
    </$ProgressBarPanelContainer>
  );
};

export default ProgressBarPanel;
