import styled from "styled-components";

import alertInfoIcon from "../../../assets/icons/alert-info.svg";
import circlePlusBlue from "../../../assets/icons/circle-plus-blue.svg";

import { colors } from "../../colors";

export const ReportWrapper = styled.div`
  font-family: "Open Sans";
  margin-top: 21px;
  // margin-bottom: 70px;
`;

export const HeaderSpacer = styled.div`
  height: 56px;
`;

export const FilterHeadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
`;

export const FilterSelectionWrapper = styled.div`
  width: 21%;
  float: left;
  margin-right: 16px;
`;

export const Panel = styled.div`
  background-color: ${colors.grayBackground};
  border: 2px solid ${colors.grayBorder};
  border-radius: 10px;
  width: 100%;
  padding: 42px;
`;

export const PanelHeadline = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.catalinaBlue};
  padding: 0px;
  margin: 0px;
`;

export const PanelTopRightButtonWrapper = styled.div`
  position: absolute;
  right: 42px;
  float: right;
  top: 42px;
`;

export const PanelHeadlineInline = styled(PanelHeadline)`
  display: inline-block;
`;

export const TextInlineHeadline = styled.span`
  font-size: 24px;
  line-height: 36px;
  color: ${colors.baseCopy};
`;

export const AlertInfo = styled(Panel)`
  background-image: url(${alertInfoIcon});
  background-size: 40px 40px;
  background-position: 42px 42px;
  background-repeat: no-repeat;
  padding: 42px 42px 42px 124px;
`;

export const AlertInfoAdd = styled(Panel)`
  background-image: url(${circlePlusBlue});
  background-size: 40px 40px;
  background-position: 42px 42px;
  background-repeat: no-repeat;
  padding: 42px 42px 42px 124px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 600px) {
    img {
      height: 50px;
    }
  }
`;

export const PanelHr = styled.hr`
  border-top: 2px solid ${colors.grayBorder};
  margin: 64px 0px;
`;

export const ArrowIndicatorWrapper = styled.span`
  margin-left: 5px;
  vertical-align: text-bottom;
  line-height: inherit;
`;

export const StyledChartLegendContainer = styled.div`
  .highcharts-legend-item > span {
    top: 0 !important;
  }
`;
