import styled from "styled-components";
import { colors } from "@styles/colors";

interface ProgressBarWrapperProps {
  backgroundColor?: string;
  borderRadius?: string;
  height?: string;
}

export const $ProgressBarWrapper = styled.div<ProgressBarWrapperProps>`
  height: ${(props) => props.height || "20px"};
  overflow: hidden;
  background-color: ${(props) => props.backgroundColor || "#E2E6FF"};
  border-radius: ${(props) => props.borderRadius || "10px"};
`;

interface ProgressBarProps {
  value?: number;
  color?: string;
  textColor?: string;
  borderRadius?: string;
}

export const $ProgressBarWrapperInside = styled.div<ProgressBarProps>`
  width: ${(props) => props.value || 0}%;
  border-radius: ${(props) => props.borderRadius || "10px"};
  float: left;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.textColor || colors.baseCopy};
  text-align: center;
  background-color: ${(props) => props.color || "#3751FF"};
  transition: width 0.6s ease;
`;
