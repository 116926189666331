import React, { FunctionComponent } from "react";
import {
  $StyledTitleContainer,
  $StyledTitleWord,
  $StyledTitleSuffix,
  $StyledTitleSuffixImg,
} from "@components/elements/StyledTitle/StyledTitle.sc";
import CTAStartFreeTrial from "./CTAStartFreeTrial";
import CTAScheduleADemo from "./CTAScheduleADemo";
import gradeusLogo from "../../../assets/gradeus-logo-transparent.png";

const CTAGradeus: FunctionComponent = (props) => {
  return (
    <>
      <$StyledTitleContainer>
        <$StyledTitleWord fontSize="24px">
          Take Control of your Reputation with
        </$StyledTitleWord>
        &nbsp; &nbsp;
        <$StyledTitleSuffix fontSize="24px">
          <$StyledTitleSuffixImg
            data-testid="pr-cta-suffix-image"
            src={gradeusLogo}
          />
        </$StyledTitleSuffix>
      </$StyledTitleContainer>
      <CTAStartFreeTrial />
      <CTAScheduleADemo />
    </>
  );
};

export default CTAGradeus;
