import React from "react";
import { $ModalHeader } from "@styles/components/elements/Headers";
import { PillLink } from "@styles/components/elements/Buttons";
import Modal from "react-modal";
import { colors } from "@styles/colors";
import { overlayBackground } from "@styles/components/base/modals/modals";

export interface IModalWithCTA {
  header: string;
  headerIcon?: any;
  children: React.ReactNode;
  ctaLabel: string;
  ctaURL: string;
  openInNewTab?: boolean;
  width?: string;
}

Modal.setAppElement("body");

const ModalWithCTA: React.FC<IModalWithCTA> = ({
  header,
  headerIcon,
  children,
  ctaLabel,
  ctaURL,
  openInNewTab = false,
  width = undefined,
}) => {
  const modalStyles = {
    content: {
      padding: "32px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: width === undefined ? "fit-content" : width,
      maxWidth: width === undefined ? "650px" : width,
      height: "fit-content",
      overflow: "hidden",
      borderRadius: "20px",
      background: colors.baseWhite,
    },
    overlay: {
      background: overlayBackground,
      zIndex: 99,
    },
  };

  return (
    <Modal isOpen={true} style={modalStyles}>
      {headerIcon && (
        <div style={{ textAlign: "center", marginBottom: "32px" }}>
          <img src={headerIcon} style={{ height: "32px" }} />
        </div>
      )}
      <$ModalHeader>{header}</$ModalHeader>
      <br />
      {children}
      <div style={{ textAlign: "center", marginTop: "24px" }}>
        <PillLink
          href={ctaURL}
          target={openInNewTab && "_blank"}
          width="auto"
          backgroundColor={colors.primaryBase}
        >
          {ctaLabel}
        </PillLink>
      </div>
    </Modal>
  );
};

export default ModalWithCTA;
