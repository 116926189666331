import React, { FunctionComponent as FC } from "react";
import ProgressBarPanel, { IProgressBarPanelProps } from "../ProgressBarPanel";
import {
  $PanelsGroupContainer,
  $PanelsGroupTitle,
} from "./ProgressBarPanelGroup.sc";

export interface IProgressBarPanelGroupProps {
  title?: string;
  panelsData: Array<IProgressBarPanelProps>;
}

const ProgressBarPanelGroup: FC<IProgressBarPanelGroupProps> = (props) => {
  return (
    <>
      {props.title && <$PanelsGroupTitle>{props.title}</$PanelsGroupTitle>}
      <$PanelsGroupContainer>
        {props.panelsData.map((badgeData, index) => {
          return <ProgressBarPanel key={index} {...badgeData} />;
        })}
      </$PanelsGroupContainer>
    </>
  );
};

export default ProgressBarPanelGroup;
