import React, { FunctionComponent } from "react";
import ProgressBarPanel, { IProgressBarPanelProps } from "../ProgressBarPanel";
import { colors } from "@styles/colors";
import { ProgressBarPanelContentStyle } from "../ProgressBarPanel/ProgressBarPanel.sc";

const contentUpStyle: ProgressBarPanelContentStyle = {
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "24px",
  marginTop: "40px",
  width: "80%",
  maxWidth: "80%",
  color: colors.tealBlue,
};

const contentDownStyle: ProgressBarPanelContentStyle = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "36px",
  marginTop: "80px",
  color: colors.baseCopy,
};

export type QuotesPanelProps = IProgressBarPanelProps & {
  quotePanelColor?: string;
};

export const QuotesPanel: FunctionComponent<QuotesPanelProps> = (props) => {
  return (
    <ProgressBarPanel
      min={0}
      max={100}
      progress={100}
      progressBarColors={[
        [props.quotePanelColor, props.quotePanelColor],
        [props.quotePanelColor, props.quotePanelColor],
      ]}
      contentUp={props.contentUp}
      contentDown={props.contentDown}
      colorThresholds={props.quotePanelColor ? [100] : null}
      styleContentUp={contentUpStyle}
      styleContentDown={contentDownStyle}
    />
  );
};

export default QuotesPanel;
