import React, { FunctionComponent } from "react";
import {
  ContactContainer,
  ContactInfo,
  ContactTitle,
  ContactTitleImg,
  ContactTitleWrapper,
} from "@styles/components/myreports/Prospect";

export type CTAWhitelabelProps = {
  whitelabelTitle?: string;
  whitelabelLogoUrl?: string;
  whitelabelContactPhone?: string;
  whitelabelContactEmail?: string;
};

const CTAWhitelabel: FunctionComponent<CTAWhitelabelProps> = (props) => {
  return (
    <ContactContainer>
      <ContactTitleWrapper>
        <ContactTitle>Take Control of your Reputation with</ContactTitle>
        {props.whitelabelLogoUrl ? (
          <ContactTitleImg src={props.whitelabelLogoUrl} />
        ) : (
          <ContactTitle data-testid="site-title">
            {props.whitelabelTitle}
          </ContactTitle>
        )}
      </ContactTitleWrapper>

      {props.whitelabelContactPhone !== null && (
        <ContactInfo href={`tel:${props.whitelabelContactPhone}`}>
          {props.whitelabelContactPhone}
        </ContactInfo>
      )}
      {props.whitelabelContactEmail !== null && (
        <ContactInfo href={`mailto:${props.whitelabelContactEmail}`}>
          {props.whitelabelContactEmail}
        </ContactInfo>
      )}
    </ContactContainer>
  );
};

export default CTAWhitelabel;
