import React, { FunctionComponent as FC } from "react";

import {
  $ProgressBarWrapper,
  $ProgressBarWrapperInside,
} from "./ProgressBar.sc";

export interface IProgressBarProps {
  backgroundColor?: string;
  textColor?: string;
  color?: string;
  text?: string;
  value: number;
  height?: string;
  borderRadius?: string;
  backgroundBorderRadius?: string;
}

const ProgressBar: FC<IProgressBarProps> = (props: IProgressBarProps) => {
  return (
    <$ProgressBarWrapper
      height={props.height}
      backgroundColor={props.backgroundColor}
      borderRadius={props.backgroundBorderRadius}
    >
      <$ProgressBarWrapperInside
        value={props.value}
        color={props.color}
        textColor={props.textColor}
        borderRadius={props.borderRadius}
      >
        {props.text}
      </$ProgressBarWrapperInside>
    </$ProgressBarWrapper>
  );
};

export default ProgressBar;
