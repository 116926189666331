import React, { FunctionComponent as FC } from "react";
import {
  $StyledTitleContainer,
  $StyledTitlePrefix,
  $StyledTitleWord,
  $StyledTitleSuffix,
} from "./StyledTitle.sc";
export interface IStyledTitleProps {
  prefix?: string;
  word?: string;
  suffix?: string;
  prefixColor?: string;
  prefixFontSize?: string;
  wordColor?: string;
  fontSize?: string;
  suffixColor?: string;
  suffixFontSize?: string;
}

const StyledTitle: FC<IStyledTitleProps> = (props) => {
  return (
    <$StyledTitleContainer>
      <$StyledTitlePrefix
        color={props.prefixColor}
        fontSize={props.prefixFontSize}
      >
        {props.prefix}
      </$StyledTitlePrefix>
      <$StyledTitleWord color={props.wordColor} fontSize={props.fontSize}>
        {props.word}
      </$StyledTitleWord>
      <$StyledTitleSuffix
        color={props.suffixColor}
        fontSize={props.suffixFontSize}
      >
        {props.suffix}
      </$StyledTitleSuffix>
    </$StyledTitleContainer>
  );
};

export default StyledTitle;
