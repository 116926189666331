import React, { FunctionComponent as FC } from "react";
import { $PanelsGroupContainer } from "../ProgressBarPanelGroup/ProgressBarPanelGroup.sc";
import { QuotesPanel, QuotesPanelProps } from "../QuotesPanel";

export interface QuotesPanelGroupProps {
  panelsData: Array<QuotesPanelProps>;
}

const QuotesPanelGroup: FC<QuotesPanelGroupProps> = ({ panelsData }) => {
  return (
    <$PanelsGroupContainer>
      {panelsData.map((panelData, index) => {
        return <QuotesPanel key={index} {...panelData} />;
      })}
    </$PanelsGroupContainer>
  );
};

export default QuotesPanelGroup;
