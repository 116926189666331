import styled from "styled-components";

import { colors } from "@styles/colors";

interface TableProps {
  condensed?: boolean;
  noStripe?: boolean;
}

export const Table = styled.table<TableProps>`
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: ${colors.baseCopy};

  th {
    font-weight: bold;
    color: ${colors.catalinaBlue};
  }

  tr {
    border-bottom: ${(props) =>
      props.noStripe ? "none" : `2px solid ${colors.grayBorder}`};
  }

  td {
    color: ${colors.baseCopy};
  }

  td,
  th {
    padding: ${(props) => (props.condensed ? "5px 5px" : "14px 16px")};
    font-size: 16px;
    line-height: 24px;
  }
`;
