import React, { FunctionComponent } from "react";
import StyledTitle from "../../elements/StyledTitle";
import CTAGradeus from "./CTAGradeus";
import CTAWhitelabel from "./CTAWhitelabel";
import {
  $MetricsOverviewContainer,
  $MetricsOverviewDescription,
} from "@styles/components/myreports/Prospect";

export type CTAProps = {
  businessName: string;
  businessLink: string;
  factorsForImprovements: string;
  whitelabelTitle?: string;
  whitelabelLogoUrl?: string;
  whitelabelCTALink?: string;
  whitelabelContactPhone?: string;
  whitelabelContactEmail?: string;
};

const CTA: FunctionComponent<CTAProps> = (props) => {
  return (
    <$MetricsOverviewContainer>
      <StyledTitle word="What should I do next?" />
      <$MetricsOverviewDescription>
        {props.factorsForImprovements !== "" && (
          <>
            <a href={props.businessLink} className="highlight" target="_blank">
              {props.businessName}
            </a>{" "}
            is losing money because of the {props.factorsForImprovements}.{" "}
          </>
        )}
        {props.whitelabelTitle ?? "Grade.us"} is a simple and effective
        reputation management tool that specializes in getting reviews quickly
        and increasing your ratings.
      </$MetricsOverviewDescription>
      {props.whitelabelTitle ? <CTAWhitelabel {...props} /> : <CTAGradeus />}
    </$MetricsOverviewContainer>
  );
};

export default CTA;
